import * as types from './types';

export default {
  [types.SET_DUMPTRUCKS](state, dumpTrucks) {
    state.dumpTrucks = dumpTrucks;
  },
  [types.SET_DUMPTRUCK](state, dumpTruck) {
    state.dumpTruck = dumpTruck;
  },
  [types.SET_UNLINKED_DUMPTRUCKS](state, unlinkedDumpTrucks) {
    state.unlinkedDumpTrucks = unlinkedDumpTrucks;
  },
  [types.UPDATE_DUMPTRUCK](state, dumpTruck) {
    const index = state.dumpTrucks.findIndex((item) => item.id === dumpTruck.id);
    if (index !== -1) {
      state.dumpTrucks.splice(index, 1, dumpTruck);
    }
  },
  [types.DELETE_DUMPTRUCK](state, dumpTruckId) {
    state.dumpTrucks = state.dumpTrucks.filter(
      (dumpTruck) => dumpTruck.id !== dumpTruckId,
    );
  },
  [types.SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
  [types.SET_CURRENT_PAGE](state, currentPage) {
    state.pagination.current_page = currentPage;
  },
};