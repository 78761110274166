import dashboardApi from '@/api/dashboard';
import * as types from './types';

export default {
  getDashboards({ commit }, params) {
    return dashboardApi.findAll(params)
      .then((response) => {
        commit(types.SET_DASHBOARD_DATA, response.data);
        return response;
      }).catch((error) => error)
  },

  getActiveShift({ commit }) {
    return dashboardApi.getActiveShift()
      .then((response) => {
        commit(types.SET_ACTIVE_SHIFT, response.data);
        return response;
      }).catch((error) => error)
  },

  filterData({ commit }, filter) {
    return dashboardApi.filterData(filter)
      .then((response) => {
        commit(types.SET_CATEGORIES, response.data.categories);
        commit(types.SET_SERIES, response.data.series);
        commit(types.SET_TARGETS, response.data.targets);
        commit(types.SET_MAX_TARGETS, response.data.maxTarget);
        commit(types.SET_MIN_TARGETS, response.data.minTarget);
        commit(types.SET_SHIFT_DATA, response.data.shifts);
        commit(types.SET_PLAN_SHIFT, response.data.planShift);
        return response;
      }).catch((error) => error)
  }
};