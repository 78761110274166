export default {
    userPermissions: (state) => {
        let permissions = null;
        if ( state.user.roles !== undefined && state.user.roles.length > 0) {
            const perms = [];
            state.user.roles.forEach(role => {
                role.permissions.forEach(permission => {
                    var exists = perms.some((el) => JSON.stringify(el) === JSON.stringify(permission));

                    if (!exists) {
                        perms.push(permission);
                    }
                });
            });

            permissions = perms;
        } else if (state.user.permissions !== undefined) {
            permissions = state.user.permissions;
        } else {
            permissions = [];
        }

        return permissions;
    },
    permissionsLoaded: (state) => {
        return state.permissionsLoaded;
    }
}