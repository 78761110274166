import { HTTP } from '@/http/index';

export default {
  fetchAll(params) {
    return HTTP.get('/targets', { params });
  },
  findZonesByMineId(mineId) {
    return HTTP.get(`/mines/${mineId}/zones`);
  },
  findTargetByZoneId(zoneId) {
    return HTTP.get(`/zones/${zoneId}/target`);
  },
  create(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.createTarget',
      successKey: 'alert.success.createTarget',
    };
    return HTTP.post('/targets', payload, { alertMessages });
  },
  linkDriverToDumpTruck(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.linkDriverToDumpTruck',
      successKey: 'alert.success.linkDriverToDumpTruck',
    };
    return HTTP.post('/driver-link-truck', payload, { alertMessages });
  },
  linkDriverToTarget(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.linkDriverToTarget',
      successKey: 'alert.success.linkDriverToTarget',
    };
    return HTTP.post('/target-link-drivers', payload, { alertMessages });
  },
  findShifts(params) {
    return HTTP.get('/shift-config', { params });
  },
};