import { HTTP } from '@/http/index';

export default {
  findAll(params) {
    return HTTP.get('/operations', { params });
  },
  findById(operationId) {
    return HTTP.get(`/operations/${operationId}`);
  },
  findAllDumpTruckOperations(params) {
    return HTTP.get('/operations-dumptrucks', { params });
  },
  create(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.createOperation',
      successKey: 'alert.success.createOperation',
    };
    return HTTP.post('/operations', payload, { alertMessages });
  },
  update(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.updateOperation',
      successKey: 'alert.success.updateOperation',
    };
    return HTTP.put(`/operationId/${payload.operationId}`, payload.data, { alertMessages });
  },
  delete(operationId) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteOperation',
      successKey: 'alert.success.deleteOperation',
    };
    return HTTP.delete(`/operations/${operationId}`, { alertMessages });
  },
  preview(payload) {
    return HTTP.post('/operations-reports', payload);
  },
  downloadReport(payload) {
    return HTTP.post('/operations-reports/download', payload, {
      responseType: 'arraybuffer',
      // headers: { Accept: 'application/pdf' },
    });
  },
  findAllLinkedDumpTrucksNonPaginate(params) {
    return HTTP.get('/operations-dumptrucks', { params });
  },
};