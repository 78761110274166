import { Ability } from '@casl/ability';
// import store from '../store';

// const defineUserAbility = () => {
  
//   const permissions = store.getters['auth/userPermissions'];

//   return defineAbility((can) => {
//     permissions.forEach((permission) => {
//       can(permission.action, permission.subject);
//     });
//     can('manage', 'all')
//   });
// }
const ability = new Ability([]);

export const updateAbility = (permissions) => {
  const rules = permissions.map(permission => ({
    action: permission.action,
    subject: permission.subject,
  }));

  ability.update(rules);
}

export default ability;