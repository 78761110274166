import { HTTP } from '@/http/index';

export default {
    fetchAllShifts() {
        return HTTP.get('/shift-config');
    },
    createShift(shift) {
        const alertMessages = {
            errorKey: 'alert.errors.createShift',
            successKey: 'alert.success.createShift',
        };
        return HTTP.post('/shift-config', shift, {  alertMessages });
    }
}