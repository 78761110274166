export default {
  drivers: [],
  driver: {},
  unlinkedDrivers: [],
  pagination: {
    last_page: undefined,
    to: undefined,
    total: undefined,
    current_page: undefined,
    from: undefined,
  },
  performance: null
};