import operationApi from '@/api/operation';
import * as types from './types';

export default {
  getOperations({ commit }, params) {
    return operationApi.findAll(params)
      .then((response) => {
        commit(types.SET_OPERATIONS, response.data.data);
        commit(types.SET_PAGINATION, response.data.meta);
        return response;
      }).catch((error) => error)
  },
  getOperationById({ commit }, operationId) {
    return operationApi.findById(operationId)
      .then((response) => {
        commit(types.SET_OPERATION, response.data.data);
        return response;
      }).catch((error) => error)
  },
  getDumpTruckOperations({ commit }, params) {
    return operationApi.findAllLinkedDumpTrucksNonPaginate(params)
      .then((response) => {
        commit(types.SET_UNLINKED_DUMP_TRUCKS_NON_PAGINATE, response.data.data);
        return response;
      }).catch((error) => error)
  },
  createOperation(context, payload) {
    return operationApi.create(payload)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  },
  updateOperation({ commit }, payload) {
    return operationApi.update(payload)
      .then((response) => {
        commit(types.UPDATE_OPERATION, response.data);
        return response;
      }).catch((error) => error)
  },
  deleteOperation({ commit }, operationId) {
    return operationApi.delete(operationId)
      .then((response) => {
        commit(types.DELETE_OPERATION, response.data);
        return response;
      }).catch((error) => error)
  },
  preview({ commit }, payload) {
    return operationApi.preview(payload)
      .then((response) => {
        commit(types.PREVIEW_REPORTS, response.data.data);
        return response;
      }).catch((error) => error)
  },
  downloadReport(context, payload) {
    return operationApi.downloadReport(payload)
      .then((response) => {
        // Create a Blob from the PDF data
        const blob = new Blob([response.data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // conversion date
        const date = new Date(payload.from_date);
        const options = {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        };

        // Create a link element and trigger a download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `operations-${date.toLocaleDateString('en-US', options)}.xlsx`;
        link.click();

        return response;
      }).catch((error) => error)
  },
};