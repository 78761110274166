import * as types from './types';

export default {
  changeLayoutType({ commit }, { layoutType }) {
    commit(types.CHANGE_LAYOUT, layoutType);
  },

  changeLayoutWidth({ commit }, { layoutWidth }) {
    commit(types.CHANGE_LAYOUT_WIDTH, layoutWidth)
  },

  changeLeftSidebarType({ commit }, { leftSidebarType }) {
    commit(types.CHANGE_LEFT_SIDEBAR_TYPE, leftSidebarType)
  },

  changeTopbar({ commit }, { topbar }) {
    commit(types.CHANGE_TOPBAR, topbar)
  },

  changeLoaderValue({ commit }, { loader }) {
    commit(types.LOADER, loader)
  }
};