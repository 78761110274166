<template>
  <div class="download-container" data-aos="fade-up">
    <div class="download-card animate__animated animate__fadeInUp">
      <img src="@/assets/images/logo/komoa_logo.png" alt="Application Logo" class="logo" />
      <h1 class="title">Téléchargez Notre Application</h1>
      <p class="description">
        Découvrez toutes les fonctionnalités exclusives de notre application directement sur votre appareil Android. Profitez d'une expérience utilisateur fluide et d'une interface moderne !
      </p>
      <a :href="backUrl" class="back-button animate__animated animate__fadeIn"> Retourner au Site </a>
      <a :href="apkUrl" class="download-button animate__animated animate__pulse" download>
        Télécharger APK
      </a>
    </div>
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'DownloadPage',
  data() {
    return {
      apkUrl: '/path/to/your-app.apk',
      backUrl: 'https://kmts.kamoacopper.com/'  // Mettez ici le chemin vers votre fichier APK
    };
  },
  mounted() {
    AOS.init();
  }
};
</script>

<style scoped>
/* Importation de la bibliothèque Animate.css */
@import "~animate.css/animate.min.css";

/* Styles de la page de téléchargement */
.download-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #ff5f6d, #ffc371);
  padding: 20px;
}

.download-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  padding: 30px;
  text-align: center;
  animation: fadeInUp 1s ease-in-out;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

.title {
  font-size: 28px;
  color: #333;
  margin-bottom: 15px;
  font-weight: bold;
}

.description {
  font-size: 16px;
  color: #666;
  margin-bottom: 25px;
}

.download-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 18px;
  color: #ffffff;
  background-color: #007bff;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #0056b3;
}
</style>
