import * as types from './types';

export default {
  [types.SET_DRIVERS](state, drivers) {
    state.drivers = drivers;
  },
  [types.SET_DRIVER](state, driver) {
    state.driver = driver;
  },
  [types.UNLINKED_DRIVERS](state, unlinkedDrivers) {
    state.unlinkedDrivers = unlinkedDrivers;
  },
  [types.UPDATE_DRIVER](state, driver) {
    const index = state.drivers.findIndex((item) => item.id === driver.id);
    if (index !== -1) {
      state.drivers.splice(index, 1, driver);
    }
  },
  [types.DELETE_DRIVER](state, driverId) {
    state.drivers = state.drivers.filter(
      (driver) => driver.id !== driverId,
    );
  },
  [types.SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
  [types.SET_CURRENT_PAGE](state, currentPage) {
    state.pagination.current_page = currentPage;
  },
  [types.SET_PERFORMANCE](state, performance) {
    state.performance = performance;
  },
};