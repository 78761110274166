import targetApi from '@/api/target';
import * as types from './types';

export default {
  fetchTargets({commit}) {
    return targetApi.fetchAll()
        .then((response) => {
          commit(types.SET_TARGETS, response.data.data);
          return response;
        })
  },
  getZonesByMineId({ commit }, mineId) {
    return targetApi.findZonesByMineId(mineId)
      .then((response) => {
        commit(types.SET_ZONES_OF_MINE, response.data);
        return response;
      }).catch((error) => error)
  },
  getTargetByZoneId({ commit }, zoneId) {
    return targetApi.findTargetByZoneId(zoneId)
      .then((response) => {
        commit(types.SET_TARGET_OF_ZONE, response.data);
        return response;
      }).catch((error) => error)
  },
  async createTarget(context, payload) {
    return await targetApi.create(payload)
      .then((response) => response)
      .catch((error) => error);
  },
  linkDriverToDumpTruck(context, payload) {
    return targetApi.linkDriverToDumpTruck(payload)
      .then((response) => response)
      .catch((error) => error)
  },
};