import { openDB } from 'idb';

const DB_NAME = 'offline-db';
const STORE_NAME = 'offline-requests';

// Ouvrir la base de données IndexedDB
async function openOfflineDB() {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      db.createObjectStore(STORE_NAME, { autoIncrement: true });
    },
  });
}

// Sauvegarder une requête hors ligne
export async function saveOfflineRequest(request) {
  if (request.method === 'post') {
    const db = await openOfflineDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    // Convertir le corps de la requête en texte si nécessaire
    const body = request.body ? await request.text() : null;

    // Sauvegarder la requête sous forme d'objet
    store.add({
      url: request.url,
      method: request.method,
      headers: Array.from(request.headers.entries()), // Convertir les en-têtes en tableau
      body,
    });
    await tx.done;
  }
}

// Obtenir toutes les requêtes hors ligne
export async function getOfflineRequests() {
  const db = await openOfflineDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  return store.getAll();
}

// Effacer toutes les requêtes hors ligne
export async function clearOfflineRequests() {
  const db = await openOfflineDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  store.clear();
  await tx.done;
}
