export default {
  dashboard: {},
  activeShift: {},
  categories: [],
  series: [],
  targets: [],
  shiftData: [],
  maxTargets: null,
  minTargets: null,
  planShift: null,
};