import * as types from './types';

export default {
  [types.LOADING_START](state) {
    state.loading.nb += 1;
    state.loading.isVisible = true;
  },
  [types.LOADING_FINISH](state) {
    state.loading.nb -= 1;
    if (state.loading.nb === 0) {
      state.loading.isVisible = false;
    }
  },
  [types.SHOW_ALERT](state, alert) {
    const alertData = {
      id: state.alerts.length + 1,
      isVisible: true,
      type: alert.type ? alert.type : 'error',
      messages: alert.messages,
      mode: alert.mode ? alert.mode : 'manual',
    };
    state.alerts.push(alertData);
  },
  [types.CLOSE_ALERT](state, id) {
    state.alerts = state.alerts.filter((alert) => alert.id !== id);
  },
  [types.SET_ONLINE_STATUS](state, status) {
    state.status = status;
  },
};