import roleApi from '@/api/role';
import * as types from './types';

export default {
  getAllPermissions({ commit }, params) {
    return roleApi.findAllPermissions(params)
      .then((response) => {
        commit(types.SET_PERMISSIONS, response.data);

        return response;
      }).catch((error) => error);
  },
  getRoles({ commit }, params) {
    return roleApi.findRoles(params)
      .then((response) => {
        commit(types.SET_ROLES, response.data);

        return response;
      }).catch((error) => error);
  },
  getUsers({ commit }, params) {
    return roleApi.findUsers(params)
      .then((response) => {
        commit(types.SET_USERS, response.data.data);
        commit(types.SET_PAGINATION, response.data.meta);

        return response;
      }).catch((error) => error);
  },

  getUnpaginateUsers({ commit }) {
    return roleApi.fetchUnpaginatedUsers()
            .then((response) => {
              commit(types.SET_UNPAGINATE_USERS, response.data.data);
            }).catch((error) => error);
  },
  createRole(context, role) {
    return roleApi.createRole(role)
      .then((response) => {
        return response;
      }).catch((error) => error);
  },
  assignRole(context, role) {
    return roleApi.assignRole(role)
      .then((response) => {
        return response;
      }).catch((error) => error);
  }
}