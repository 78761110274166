import * as types from './types';

export default {
  startLoading(context) {
    context.commit(types.LOADING_START);
  },
  finishLoading(context) {
    context.commit(types.LOADING_FINISH);
  },
  showAlert(context, alert) {
    context.commit(types.SHOW_ALERT, alert);
  },
  closeAlert(context, alertId) {
    context.commit(types.CLOSE_ALERT, alertId);
  },
  setOnlineStatus({ commit }, status) {
    commit('SET_ONLINE_STATUS', status);
  }
};