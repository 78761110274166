import shiftApi from '@/api/shift';
import * as types from './types';

export default {
    createShift({ commit }, shift) {
        console.log("data : ", shift.type);
        return shiftApi.createShift(shift).then((response) => {
            commit(types.SET_SHIFT, response.data);
           return response;
        }).catch((err) => err);
    },
    getAllShifts({ commit}) {
        return shiftApi.fetchAllShifts().then((response) => {
            commit(types.SET_SHIFTS, response.data);
    }).catch((err) => err);
    }
}