import * as types from './types';

export default {
  [types.SET_USER_SESSION_DATA](state, sessionData) {
    localStorage.setItem('ugdb-session', JSON.stringify(sessionData));
  },
  [types.LOGOUT_USER]() {
    localStorage.clear();
    location.reload();
  },
  [types.SET_USER_INFO](state, user) {
    
    localStorage.setItem('user', JSON.stringify(user));
  },
  [types.SET_USER_INFO_STATE](state, user) {
    state.user = user;
  },
};