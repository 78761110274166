import * as types from "./types";

export default {
    [types.SET_ZONES](state, zones) {
        state.zones = zones;
    },
    [types.SET_ZONE](state, zone) {
        state.zone = zone;
    },
    [types.SET_TONNAGE](state, tonnage) {
        state.tonnage = tonnage;
    }
}