export default {
  operations: [],
  operation: {},
  pagination: {
    last_page: undefined,
    to: undefined,
    total: undefined,
    current_page: undefined,
    from: undefined,
  },
  dumptrucks: [],
  reports: []
};