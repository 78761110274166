<template>
  <router-view></router-view>
  <Loader v-if="isLoading" :isLoading="isLoading" />
</template>

<script>
import Loader from "@/components/loader"
import { mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    Loader,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.application.loading.isVisible,
    }),
  },
}
</script>
