import actions from './actions';
import mutations from './mutations';
import state from './state';
import getters from './getters';
  
  export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations,
  };
  