import { HTTP } from '@/http/index';

export default {
  findAllPermissions(params) {
    return HTTP.get('/permissions', { params });
  },
  findRoles(params) {
    return HTTP.get('/roles', { params });
  },
  findUsers(params) {
    return HTTP.get('/users', { params });
  },
  fetchUnpaginatedUsers() {
    return HTTP.get('/get-users');
  },
  createRole(role) {
    const alertMessages = {
      errorKey: 'alert.errors.createRole',
      successKey: 'alert.success.createRole',
    };
    return HTTP.post('/roles', role, {  alertMessages });
  },
  assignRole(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.assignRole',
      successKey: 'alert.success.assignRole',
    };
    return HTTP.post('/assign', payload, {  alertMessages });
  },
};