import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueApexCharts from 'vue3-apexcharts';
import vClickOutside from 'click-outside-vue3';
import { registerScrollSpy } from 'vue3-scroll-spy';

import Maska from 'maska';
import i18n from './i18n';
import { abilitiesPlugin } from '@casl/vue';
import ability from './services/ability';

import BootstrapVueNext from 'bootstrap-vue-next';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import interceptorsSetup from '@/http/interceptors';

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import store from './store';

import '../src/design/app.scss';
import './registerServiceWorker';

import VDatePicker from 'v-calendar';
import 'v-calendar/style.css';
// import ability from './store/modules/ability';
// import defineUserAbility from './services/ability';

// const ability = defineUserAbility([]);
// Setup interceptors
interceptorsSetup();

// Create and mount Vue application
const app = createApp(App);

app.use(store)
   .use(router)
   .use(require('vue-chartist'))  // Ensure this plugin is correctly installed
   .use(BootstrapVueNext)
   .use(VueApexCharts)
   .use(vClickOutside)
   .use(i18n)
   .use(registerScrollSpy)
   .use(Maska)
   .use(VDatePicker)
   .use(abilitiesPlugin, ability, {
     useGlobalProperties: true
   });

// Initialize AOS
AOS.init();

// Mount the application
app.mount('#app');

export { app };
