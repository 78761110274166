export default {
    configurations: [],
    sites: [],
    mines: {},
    zones: [],
    sitesMines: [],
    minesZones: [],
    unpaginateSites: [],
    unpaginateMines: [],
    unpaginateZones: []
};