import * as types from './types';

export default {
    [types.SET_SHIFTS](state, shifts) {
        state.shifts = shifts;
    },
    [types.SET_SHIFT](state, shift) {
        state.shift = shift;
    },
    [types.SET_SHIFT](state, shift) {
        const index = state.shifts.findIndex((e) => e.type === shift.type);
        if (index !== -1) {
            state.shifts.splice(index, 1, shift);
        } else {
            state.shifts.push(shift);
        }
    }
}