import { HTTP } from '@/http/index';

export default {
  findAll(params) {
    return HTTP.get('/drivers', { params });
  },
  findById(driverId) {
    return HTTP.get(`/drivers/${driverId}`);
  },
  findUnlikedDrivers() {
    return HTTP.get('/unlinked-drivers');
  },
  create(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.createDriver',
      successKey: 'alert.success.createDriver',
    };
    return HTTP.post('/drivers', payload, { alertMessages });
  },
  update(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.updateDriver',
      successKey: 'alert.success.updateDriver',
    };
    return HTTP.put(`/drivers/${payload.driverId}`, payload.data, { alertMessages });
  },
  delete(driverId) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteDriver',
      successKey: 'alert.success.deleteDriver',
    };
    return HTTP.delete(`/drivers/${driverId}`, { alertMessages });
  },
  getPerformances(data) {
    return HTTP.post('/performance-drivers', data);
  }
};