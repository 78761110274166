import activityApi from '@/api/activity';
import * as types from './types';

export default {
  getActivities({ commit }, params) {
    return activityApi.findAll(params)
      .then((response) => {
        commit(types.SET_ACTIVITIES, response.data);
        return response;
      }).catch((error) => error);
  },
};