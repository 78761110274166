import { HTTP } from '@/http/index';

export default {
  findAllData(params) {
    return HTTP.get('/get-all-data', { params });
  },
  fecthUnpaginateSites(params) {
    return HTTP.get('/sites-all', { params });
  },
  fecthUnpaginateMines(params) {
    return HTTP.get('/mines-all', { params });
  },
  fecthUnpaginateZones(params) {
    return HTTP.get('/zones-all', { params });
  },
  findAllSites(params) {
    return HTTP.get('/sites', { params });
  },
  findAllMines(params) {
    return HTTP.get('/mines', { params });
  },
  findMinesBySiteId(siteId) {
    return HTTP.get(`/sites/${siteId}/mines`);
  },
  findAllZones(params) {
    return HTTP.get('/zones', { params })
  },
  findZonesByMineId(mineId) {
    return HTTP.get(`/mines/${mineId}/zones`);
  },
  createSite(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.createSite',
      successKey: 'alert.success.createSite',
    };
    return HTTP.post('/sites', payload, { alertMessages });
  },
  createMine(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.createMine',
      successKey: 'alert.success.createMine',
    };
    return HTTP.post('/mines', payload, { alertMessages });
  },
  createZone(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.createZone',
      successKey: 'alert.success.createZone',
    };
    return HTTP.post('/zones', payload, { alertMessages });
  },
};