import zoneApi from '@/api/zone';
import * as types from './types';

export default {
    fetchAllZones({ commit }) {
        return zoneApi.fetchAll().then((response) => {
            commit(types.SET_ZONES, response.data);
        });
    },
    getTonnage({ commit}, data) {
        return zoneApi.getTonnage(data).then((response) => {
            commit(types.SET_TONNAGE, response.data.total_tonnage);
        });
    }
}