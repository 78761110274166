import * as types from './types';

export default {
  [types.SET_OPERATIONS](state, operations) {
    state.operations = operations;
  },
  [types.SET_UNLINKED_DUMP_TRUCKS_NON_PAGINATE](state, dumptrucks) {
    state.dumptrucks = dumptrucks;
  },
  [types.SET_OPERATION](state, operation) {
    state.operation = operation;
  },
  [types.UPDATE_OPERATION](state, operation) {
    const index = state.operations.findIndex((item) => item.id === operation.id);
    if (index !== -1) {
      state.operations.splice(index, 1, operation);
    }
  },
  [types.DELETE_OPERATION](state, operationId) {
    state.operations = state.operations.filter(
      (operation) => operation.id !== operationId,
    );
  },
  [types.SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
  [types.SET_CURRENT_PAGE](state, currentPage) {
    state.pagination.current_page = currentPage;
  },
  [types.PREVIEW_REPORTS](state, reports) {
    state.reports = reports;
  },
};