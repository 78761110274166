import * as types from './types';

export default {
  [types.SET_DASHBOARD_DATA](state, dashboard) {
    state.dashboard = dashboard;
  },
  [types.SET_ACTIVE_SHIFT](state, shift) {
    state.activeShift = shift;
  },
  [types.SET_CATEGORIES](state, categories) {
    state.categories = categories;
  },
  [types.SET_SERIES](state, series) {
    state.series = series;
  },
  [types.SET_TARGETS](state, targets) {
    state.targets = targets;
  },
  [types.SET_MAX_TARGETS](state, maxTargets) {
    state.maxTargets = maxTargets;
  },
  [types.SET_MIN_TARGETS](state, minTargets) {
    state.minTargets = minTargets;
  },
  [types.SET_SHIFT_DATA](state, shiftData) {
    state.shiftData = shiftData;
  },
  [types.SET_PLAN_SHIFT](state, planShift) {
    state.planShift = planShift;
  },
};