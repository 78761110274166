import * as types from './types';

export default {
  [types.CHANGE_LAYOUT](state, layoutType) {
    state.layoutType = layoutType;
  },
  [types.CHANGE_LAYOUT_WIDTH](state, layoutWidth) {
    state.layoutWidth = layoutWidth;
  },
  [types.CHANGE_LEFT_SIDEBAR_TYPE](state, leftSidebarType) {
    state.leftSidebarType = leftSidebarType;
  },
  [types.CHANGE_TOPBAR](state, topbar) {
    state.topbar = topbar;
  },
  [types.LOADER](state, loader) {
    state.loader = loader
  }
};